import { Container, Grid } from "@mui/material";

import { Title, Line, Text } from "../../../../components";

const OurPartners = () => {
  const list1 = [
    {
      strong: "1.С общественными организациями: ",
      text: "Коллективное Соглашение о взаимодействии от 06 апреля 2019 г.",
    },
    {
      strong:
        "«Местная национально-культурная автономия татар городского округа Симферополь «Идель»",
    },
    {
      strong:
        "«Национально-культурная автономия татар поволжья городского округа Алушта»",
    },
    {
      strong:
        "«Севастопольское национально-культурное общество казанских татар и башкир».",
    },
    {
      strong: "2. С ООО «АЙПРО» (Продюсерский центр Рената Ибрагимова iPro). ",
      text: "Исполнительный директор - Галямшин Азат Ринатович. Соглашение о взаимодействии от 09 апреля 2019 г.",
    },
    {
      strong:
        "3. С Государственным бюджетным учреждением Республики Крым «Восточно Крымский историко - культурный музей-заповедник» г.Керчь. ",
      text: "Генеральный директор Умрихина Т.В. Соглашение о взаимодействии от 26 апреля 2019 г.",
    },
    {
      strong: "4. С Общественной палатой Республики Крым. ",
      text: "Председатель Общественной палаты Крыма – Григорий Адольфович Иоффе. Соглашение о сотрудничестве от 29 марта 2019 г.",
    },
    {
      strong:
        "5. С Общероссийской Общественной Организацией «Всероссийский комитет по разрешению экономических конфликтов и защите прав граждан». ",
      text: "Председатель - Джабраилов Сулиман Хусейнович. Соглашение о взаимодействии от 01 июля 2019 г.",
    },
    {
      strong:
        "6. С Общественным советом Департамента капитального строительства города Севастополь. ",
      text: "Председатель - Шулин Станислав Михайлович. Соглашение о взаимодействии от 12 июля 2019 г.",
    },
    {
      strong: "7. С Центральным Советом ВОСВОД России. ",
      text: "Председатель - генерал-полковником Нелезин Петр Васильевич. Камальдинову Р.С. присвоено звание майора ВОСВОД с полномочиями советника представителя генерал-полковника Нелезина П.В. по Крыму и г. Севастополь (приказ № 212/ЦС от 28.11.2019 г.)",
    },
    {
      strong: "8. С Межрегиональным профсоюзом «Единство». ",
      text: "Председатель - Волков Анатолий Сергеевич. Соглашение о взаимодействии от 15 января 2020 г.",
    },
    {
      strong: "9. С ООО «Экспокрым» ",
      text: "Генеральный директор - Семерецкий Сергей Михайлович. Соглашение о взаимодействии от 15 января 2020 г.",
    },
    {
      strong:
        "10. С Региональной общественной организацией «Союз пограничников Крыма». ",
      text: "Председатель Совета РОО «Союз пограничников Крыма» Дьяченко Александр Георгиевич. Соглашение о взаимодействии от 18.01.2020 г.",
    },
    {
      strong:
        "11. С Региональной общественной организацией по укреплению физического и духовного здоровья нации «Чистый Крым». ",
      text: "Директор – Космач Борис Владимирович. Соглашение о взаимодействии от 20 января 2020 г.",
    },
    {
      strong: "12. С Крымской региональной организацией ООО «ВОСВОД». ",
      text: "Председатель Совета – Шишков Олег Георгиевич. Соглашение о взаимодействии от 23.01.2020 г.",
    },
    {
      strong:
        "13. С Государственным бюджетным учреждением Республики Крым «Дом Дружбы народов». ",
      text: "Директор – Сокирская Лариса Васильевна. Соглашение о сотрудничестве от 28.01.2020 г.",
    },
    {
      strong:
        "14. С Общественной организацией Республики Саха (Якутия) Татарский культурный центр «Туган як». ",
      text: "Председатель Халилов Игорь Накипович. Соглашение о взаимодействии от 27.02.2020 г.",
    },
    {
      strong:
        "15. С Межрегиональной общественной организацией «Ассоциация предпринимателей Республики Крым и г. Севастополь» ",
      text: "Генеральный директор – Бекиров Гирей Шевкиевич. Соглашение о взаимодействии от 28 февраля 2020 г.",
    },
    {
      strong:
        "16. С Крымской региональной физкультурно-спортивной общественной организацией «Федерация Ден-то Фудокан каратэ-до Республики Крым» ",
      text: "Президент – Бунчук Сергей Иванович. Соглашение о взаимодействии от 06.03.2020 г.",
    },
    {
      strong:
        "17. С Крымской региональной общественно-спортивной организацией «Центр содействия развитию патриотического и спортивного воспитания молодежи «Сыны Отечества». ",
      text: "Председатель правления – Бунчук Сергей Иванович. Соглашение о взаимодействии от 06.03.2020 г.",
    },
    {
      strong: "18. ООО «Научно-исследовательский Центр религиозного питания» ",
      text: "Директор – Галеев Алексей Станиславович. Соглашение о взаимодействии от 21.05.2020 г.",
    },
    {
      strong: "19. С Общественной палатой города Севастополь ",
      text: "Председатель Потемкин Сергей Арифович. Соглашение о взаимодействии от 22.06.2020 г.",
    },
    {
      strong: "20. С ООО «РИФ-СЕРВИС». ",
      text: "Генеральный директор – Идрисов Марат Ахтямович. Соглашение о взаимодействии от 29.06.2020 г.",
    },
    {
      strong: "21. С ООО «Частная охранная организация «СОЮЗ». ",
      text: "Директор – Ушаков Артем Валентинович. Соглашение о взаимодействии от 29.06.2020 г.",
    },
    {
      strong: "22. С ООО «АКТИВ ГРУПП» ",
      text: "Генеральный директор – Семенцов Дмитрий Викторович. Соглашение о взаимодействии от 09.07.2020 г.",
    },
    {
      strong:
        "23. С Общероссийской общественной организацией «Союз садоводов России» в Республике Крым. ",
      text: "Заместитель председателя – Олейник Александр Николаевич. Соглашение о взаимодействии от 14.07.2020 г.",
    },
    {
      strong: "24. С ООО «ЭРБЕК» (турецкая строительная компания). ",
      text: "Генеральный директор – Бекар Эрджан. Соглашение о взаимодействии от 22.07.2020 г.",
    },
    {
      strong: "25. С Ассоциацией русских и турецких бизнесменов Черного моря. ",
      text: "Президент – Бекар Эрджан. Соглашение о взаимодействии от 22.07.2020 г.",
    },
    {
      strong:
        "26. С Крымским региональным отделением Общероссийской общественной организации «Федерация кикбоксинга России». ",
      text: "Председатель – Олейник Владимир Анатольевич. Соглашение о взаимодействии от 21.08.2020 г.",
    },
    {
      strong:
        "27. С ООО «ТВ Студия Август» (Всероссийский тв проект «Земля людей») ",
      text: "Генеральный директор – Коряковцева А.М. Соглашение о взаимодействии от 17.09. 2020 г.",
    },
    {
      strong:
        "28. С Крымской республиканской региональной организацией общероссийской общественной организации «Всероссийское общество инвалидов». ",
      text: "Председатель – Поддубный Сергей Анатольевич. Соглашение о взаимодействии от 30.09. 2020 г.",
    },
    {
      strong: "29. С ООО «Четра» (Чебоксарский тракторный завод). ",
      text: "Исполнительный директор – Антонов Владимир Сергеевич. Соглашение о сотрудничестве от 08.10.2020 г.",
    },
    {
      strong:
        "30. С Региональной общественной организацией «Благомир» (Москва) ",
      text: "и.о. Исполнительного директора - Рыкова Лариса Розиковна. Соглашение о взаимодействии от 22.10.2020 г.",
    },
    {
      strong: "31. С ООО «Миасский завод медицинского оборудования». ",
      text: "Исполнительный директор – Гринь Виктор Васильевич. Соглашение о взаимодействии от 19.11.2020 г.",
    },
    {
      strong:
        "32. С Региональной патриотической общественной организацией «Бессмертный полк – Севастополь». ",
      text: "Председатель – Прудникова Нина Дмитриевна. Соглашение о взаимодействии от 03.12.2020 г.",
    },
    {
      strong: "33. ООО «ИВТрейд» (СПб). ",
      text: "Генеральный директор Грязнова – Марина Юрьевна. Соглашение о взаимодействии от 11.12.2020 г.",
    },
    {
      strong:
        "34. Со Штабом общественной поддержки Партии «ЕДИНАЯ РОССИЯ» в Республике Крым. ",
      text: "Руководитель – Лантух Наталья Андреевна. Соглашение о сотрудничестве от 23.08.2021г.",
    },
    {
      strong:
        "35. С Организационным комитетом Партии «ЗА ОТЕЧЕСТВО» РЕСПУБЛИКИ БЕЛАРУСЬ. ",
      text: "Председатель – Иванов Андрей Аркадьевич. Соглашение о взаимодействии от 10.11.2021г.",
    },
    {
      strong: "36. С Русским Географическим Обществом по Республике Крым. ",
      text: "Председатель – Самохин Геннадий Викторович. Соглашение о взаимодействии от 27.04.2022г.",
    },
    {
      strong:
        "37. С Торгово-промышленной Российско-Венесуэльской палатой «КАВЕНРУ» ",
      text: "Президент – Фроленко Роман Геннадьевич. Соглашение о взаимодействии от 12.08.2022г",
    },
    {
      logo: "/img/arena_logo.svg",
      qr: "/img/arena_qr.svg",
      url: "https://cloud.mail.ru/public/WBFY/kq9rVEeZs",
      strong: "38. ООО «АРЕНА ФОРС - КРЫМ» ",
      text: "Генеральный директор – Нечаев Сергей Анатольевич. Соглашение о взаимодействии от 15.02.2023г.",
    },
    {
      logo: "/img/osher_logo.svg",
      qr: "/img/osher_qr.svg",
      url: "https://osher.tech",
      strong: "39. ООО «ОШЕР» ",
      text: "Генеральный директор - Белостоцкий Виктор Юрьевич. Соглашение о взаимодействии от 12 февраля 2023 г.",
    },
    {
      logo: "/img/ravilian_logo.svg",
      qr: "/img/ravilian_qr.svg",
      url: "https://cloud.mail.ru/public/Nytg/TgY7sjKgp",
      strong: "40. ООО «РАВИЛИАН» ",
      text: "Генеральный директор – Пелеванов Талят Назимович. Соглашение о взаимодействии от 22.02.2023г.",
    },
  ];
  return (
    <Container
      id="partners"
      maxWidth="xl"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 100,
      }}
    >
      <Line />
      <Title>НАШИ ПАРТНЕРЫ</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {list1
            .filter((item, index) => index <= (list1.length - 1) / 2)
            .map((item, index) => (
              <div
                style={{
                  marginTop: 20,
                  display: "flex",
                  padding: "5px",
                  alignItems: "center",
                  gap: 30,
                  border: "1px solid #B7B7B7",
                }}
              >
                {item?.logo ? <img src={item?.logo} /> : null}
                <Text
                  style={{
                    color: "#3A586E",
                    fontWeight: 40,
                    fontSize: 14,
                  }}
                  key={index}
                >
                  <strong>{item.strong}</strong>
                  {item.text}
                </Text>
                {item?.qr ? <img src={item?.qr} /> : null}
              </div>
            ))}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {list1
            .filter((item, index) => index > (list1.length - 1) / 2)
            .map((item, index) => (
              <a
                style={{
                  marginTop: 20,
                  display: "flex",
                  padding: "5px",
                  alignItems: "center",
                  gap: 30,
                  border: "1px solid #B7B7B7",
                  cursor: item?.url ? "pointer" : "",
                  textDecoration: "none",
                }}
                target="_blank"
                href={item?.url}
              >
                {item?.logo ? <img src={item?.logo} /> : null}
                <Text
                  style={{
                    color: "#3A586E",
                    fontWeight: 40,
                    fontSize: 14,
                  }}
                  key={index}
                >
                  <strong>{item.strong}</strong>
                  {item.text}
                </Text>
                {item?.qr && window.innerWidth > 786 ? (
                  <img src={item?.qr} />
                ) : null}
              </a>
            ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default OurPartners;
