import { Box, Container } from "@mui/material";
import { styled } from "@mui/system";
import {
  YMaps,
  Map,
  Placemark,
  TypeSelector,
  Polygon,
} from "react-yandex-maps";
import { Line, Title } from "../../../../components";

const CustomContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  gap: "30px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const MapBox = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.down("sm")]: {
    height: "60vh",
  },
}));

const HubMap = () => {
  return (
    <Container
      id="hub-map"
      maxWidth="xl"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 100,
      }}
    >
      <Line />
      <Title>ПЕРЕВАЛОЧНЫЙ ХАБ СТРОЙМАТЕРИАЛОВ В КРЫМУ</Title>
      {/* <Box> */}
      <CustomContainer>
        <MapBox>
          <YMaps>
            <Map
              width={"100%"}
              height={"100%"}
              defaultState={{ center: [45.125563, 34.009155], zoom: 17 }}
            >
              <TypeSelector options={{ float: "right" }} />
              <Placemark geometry={[45.125563, 34.009155]} />
              <Polygon
                geometry={[
                  [
                    [45.126469, 34.008656],
                    [45.125428, 34.007607],
                    [45.124974, 34.00847],
                    [45.125259, 34.008761],
                    [45.124648, 34.010054],
                    [45.125394, 34.010789],
                  ],
                ]}
                options={{
                  strokeColor: "#0000FF",
                  opacity: 0.5,
                  strokeWidth: 2,
                }}
              />
            </Map>
          </YMaps>
        </MapBox>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <img src="/img/hub_map_1.svg" />
          <img src="/img/hub_map_2.svg" />
        </Box>
      </CustomContainer>
      {/* </Box> */}
    </Container>
  );
};

export default HubMap;
