const personList = [
  {
    img: "/img/Rectangle23.png",
    full_name: "Камальдинов Рамиль Салимджанович",
    description: [
      {
        post: "Председатель ",
        label: "КРОО “Крымское Землячество Республики Татарстан”, ",
      },
      {
        post: "Совладелец ",
        label: "ТД “РегионСнаб”, ",
      },
      {
        post: "Председатель ",
        label:
          "Комиссии “Гранты Инвестиции Субсидии Стартапы” Регионального Отделения Русского Географического Общества в Республике Крым, ",
      },
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Запорожской и Херсонской области ",
      },
    ],
  },
  {
    img: "/img/Rectangle24.png",
    full_name: "Михайлов Анатолий Николаевич",
    description: [
      {
        post: "Сопредседатель ",
        label: "КРОО “Крымское Землячество Республики Татарстан”, ",
      },
      {
        post: "Совладелец ",
        label: "ТД “РегионСнаб”, ",
      },
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Республике Саха (Якутия), ",
      },
      {
        post: "Директор ",
        label: "по развитию и продвижению ТД “РегионСнаб”, ",
      },
      {
        post: "Основатель ",
        label: "Аптеки “Виват” в Республике Саха (Якутия)",
      },
    ],
  },
  {
    img: "/img/Rectangle25.png",
    full_name: "Лысенко Григорий Александрович",
    description: [
      {
        post: "Юрист консульт ",
        label:
          "ТД “РегионСнаб”, КРОО “Крымское Землячество Республики Татарстан”, ",
      },
      {
        post: "Подполковник запаса ",
        label: "МВД РК",
      },
    ],
  },
  {
    img: "/img/Victor.png",
    full_name: "Белостоцкий Виктор Юрьевич",
    description: [
      {
        post: "Директор ",
        label: "по IT технологиям ТД “РегионСнаб”, ",
      },
      {
        post: "Основатель и генеральный директор ",
        label: "компании ООО “ОШЕР” в Республике Саха (Якутия)",
      },
    ],
  },
  {
    img: "/img/danya.png",
    full_name: "Романов Даниил Егорович",
    description: [
      {
        post: "IT технолог ",
        label: "ТД “РегионСнаб”, ",
      },
      {
        post: "UX/UI designer ",
        label: "компании ООО “ОШЕР”",
      },
    ],
  },
  {
    img: "/img/Rectangle26.png",
    full_name: "Орлов Владислав Алексеевич",
    description: [
      {
        post: "Руководитель проекта ",
        label: "“Вкусняшки от татарина”, ",
      },
      {
        post: "Руководитель департамента ",
        label: "делопроизводства ТД “РегионСнаб”",
      },
    ],
  },
  {
    img: "/img/aman.png",
    full_name: "Актаев Аманжол Еркишович",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Казахстану ",
      },
      {
        post: "Заместитель генерального директора по безопасности ",
        label: "Строительно Дорожной Компании “Сине Мидас Строй”",
      },
    ],
  },
  {
    img: "/img/uzbek.png",
    full_name: "Джураев Бахтиёр Уктамович",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Узбекистану ",
      },
    ],
  },
  {
    img: "/img/oleg.JPG",
    full_name: "Степанович Олег Анатольевич",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Белоруссии ",
      },
      {
        post: "Заместитель директора ",
        label: "по логистике ООО “ГрандГранит” ",
      },
      {
        post: "Заместитель руководителя ",
        label:
          "Молодежного центра преподобного Серафима Саровского при храме Оптинских Старцев Белорусской Православной Церкви ",
      },
    ],
  },
  {
    img: "/img/anastasia.jpeg",
    full_name: "Леушина Анастасия Владимировна",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Татарстану ",
      },
    ],
  },
  {
    img: "/img/damir.png",
    full_name: "Галеев Дамир Инсафович",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Москве ",
      },
      {
        post: "Генеральный директор ",
        label: "ГК “Policom” ",
      },
    ],
  },
  {
    img: "/img/vladimir.jpeg",
    full_name: "Новак Владимир Леонидович",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Екатеринбургу ",
      },
      {
        post: "Вице-президент Председатель комитета ",
        label: "по инвестициям и внешне-экономическим связям",
      },
    ],
  },
];

const flagsList = [
  {
    img: "/img/krum.png",
    full_name: "Камальдинов Рамиль Салимджанович",
    region: "РЕСПУБЛИКА КРЫМ",
    avatar: "/img/Rectangle23.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Запорожской и Херсонской области ",
      },
      {
        post: "Совладелец ",
        label: "ТД “РегионСнаб”, ",
      },
      {
        post: "Председатель ",
        label: "КРОО “Крымское Землячество Республики Татарстан”, ",
      },
      {
        post: "Председатель ",
        label:
          "Комиссии “Гранты Инвестиции Субсидии Стартапы” Регионального Отделения Русского Географического Общества в Республике Крым, ",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 978 093 83 00"],
      },
      {
        img: "/img/mess.svg",
        label: "Вконтакте",
        type: "link",
        content: ["https://vk.com/club181465754", "https://vk.com/id713537371"],
      },
    ],
    address: "Адрес: РК, г. г. Симферополь, ул. Пролетарская 2/17, оф. 201",
  },
  {
    img: "/img/zap.png",
    full_name: "Камальдинов Рамиль Салимджанович",
    region: "ЗАПОРОЖСКАЯ ОБЛАСТЬ",
    avatar: "/img/Rectangle23.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Запорожской и Херсонской области ",
      },
      {
        post: "Совладелец ",
        label: "ТД “РегионСнаб”, ",
      },
      {
        post: "Председатель ",
        label: "КРОО “Крымское Землячество Республики Татарстан”, ",
      },
      {
        post: "Председатель ",
        label:
          "Комиссии “Гранты Инвестиции Субсидии Стартапы” Регионального Отделения Русского Географического Общества в Республике Крым, ",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 978 093 83 00"],
      },
      {
        img: "/img/mess.svg",
        label: "Вконтакте",
        type: "link",
        content: ["https://vk.com/club181465754", "https://vk.com/id713537371"],
      },
    ],
    address: "Адрес: РК, г. г. Симферополь, ул. Пролетарская 2/17, оф. 201",
  },
  {
    img: "/img/herson.png",
    full_name: "Камальдинов Рамиль Салимджанович",
    region: "ХЕРСОНСКАЯ ОБЛАСТЬ",
    avatar: "/img/Rectangle23.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Запорожской и Херсонской области ",
      },
      {
        post: "Совладелец ",
        label: "ТД “РегионСнаб”, ",
      },
      {
        post: "Председатель ",
        label: "КРОО “Крымское Землячество Республики Татарстан”, ",
      },
      {
        post: "Председатель ",
        label:
          "Комиссии “Гранты Инвестиции Субсидии Стартапы” Регионального Отделения Русского Географического Общества в Республике Крым, ",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 978 093 83 00"],
      },
      {
        img: "/img/mess.svg",
        label: "Вконтакте",
        type: "link",
        content: ["https://vk.com/club181465754", "https://vk.com/id713537371"],
      },
    ],
    address: "Адрес: РК, г. г. Симферополь, ул. Пролетарская 2/17, оф. 201",
  },
  {
    img: "/img/krsnk.png",
    full_name: "Сынаа Айдыс Валериевич",
    region: "КРАСНОЯРСК",
    avatar: "/img/synaa.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Красноярску, Хакасии, Тыве",
      },
      {
        post: "Председатель ",
        label: "Тувинского Регионального отделения “Опора России”",
      },
      {
        post: "Владелец ",
        label: "магазина одежды “BLOOM”",
      },
      {
        post: "Эксперт ",
        label:
          "по вопросам юридического сопровождения деятельности организаций",
      },
      {
        post: "Адвокат ",
        label: "по уголовным, гражданским и арбитражным делам",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.tva@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 913 354 78 87"],
      },
    ],
    address: "Адрес представительства: г. Кызыл, ул. Кочетова 34а",
  },
  {
    img: "/img/tuva.png",
    full_name: "Сынаа Айдыс Валериевич",
    region: "РЕСПУБЛИКА ТЫВА",
    avatar: "/img/synaa.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Красноярску, Хакасии, Тыве",
      },
      {
        post: "Председатель ",
        label: "Тувинского Регионального отделения “Опора России”",
      },
      {
        post: "Владелец ",
        label: "магазина одежды “BLOOM”",
      },
      {
        post: "Эксперт ",
        label:
          "по вопросам юридического сопровождения деятельности организаций",
      },
      {
        post: "Адвокат ",
        label: "по уголовным, гражданским и арбитражным делам",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.tva@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 913 354 78 87"],
      },
    ],
    address: "Адрес представительства: г. Кызыл, ул. Кочетова 34а",
  },
  {
    img: "/img/hakas.png",
    full_name: "Сынаа Айдыс Валериевич",
    region: "РЕСПУБЛИКА ХАКАСИЯ",
    avatar: "/img/synaa.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Красноярску, Хакасии, Тыве",
      },
      {
        post: "Председатель ",
        label: "Тувинского Регионального отделения “Опора России”",
      },
      {
        post: "Владелец ",
        label: "магазина одежды “BLOOM”",
      },
      {
        post: "Эксперт ",
        label:
          "по вопросам юридического сопровождения деятельности организаций",
      },
      {
        post: "Адвокат ",
        label: "по уголовным, гражданским и арбитражным делам",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.tva@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 913 354 78 87"],
      },
    ],
    address: "Адрес представительства: г. Кызыл, ул. Кочетова 34а",
  },
  {
    img: "/img/kaz.png",
    full_name: "Актаев Аманжол Еркишович",
    region: "РЕСПУБЛИКА КАЗАХСТАН",
    avatar: "/img/aman.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Республике Казахстан",
      },
      {
        post: "Заместитель Генерального директора по безопасности ",
        label: "Строительно Дорожной Компании “Сине Мидас Строй”",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.kz@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 701 233 82 22"],
      },
      {
        img: "/img/mess.svg",
        label: "Telegram",
        type: "telegram",
        content: ["+7 701 233 82 22"],
      },
      {
        img: "/img/mess.svg",
        label: "WhatsApp",
        type: "whatsapp",
        content: ["+7 701 233 82 22"],
      },
    ],
    address:
      "Адрес представительства: Казахстан, г. Астана, ул. Чингиза Айтматова 46/1",
  },
  {
    img: "/img/rsya.png",
    full_name: "Михайлов Анатолий Николаевич",
    region: "РЕСПУБЛИКА САХА (ЯКУТИЯ)",
    avatar: "/img/Rectangle24.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Республике Саха (Якутия)",
      },
      {
        post: "Совладелец ",
        label: "ТД “РегионСнаб”",
      },
      {
        post: "Сопредседатель ",
        label: " КРОО “Крымское Землячество Республики Татарстан”",
      },
      {
        post: "Директор ",
        label: "по развитию и продвижению ТД “РегионСнаб”",
      },
      {
        post: "Основатель ",
        label: "Аптеки “Виват” в Республике Саха (Якутия)",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.rsya@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 914 234 26 13"],
      },
    ],
    address:
      "Адрес представительства: Республика Саха (Якутия), г. Якутск, ул. Лермонтова 38, офис 1А",
  },
  {
    img: "/img/uzb.png",
    full_name: "Джураев Бахтиёр Уктамович",
    region: "УЗБЕКИСТАН",
    avatar: "/img/uzbek.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Республике Узбекистан",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.uzb@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+998 90 101 00 01", "+998 97 777 26 28"],
      },
    ],
    address:
      "Адрес представительства: Республика Узбекистан, г. Ташкент, Юнусабадский район, ул. Амира Темура 95А, Бизнес Центр, 5 этаж, офис 503",
  },
  {
    img: "/img/bel.png",
    full_name: "Степанович Олег Анатольевич",
    region: "БЕЛОРУССИЯ",
    avatar: "/img/oleg.JPG",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Белоруссии ",
      },
      {
        post: "Заместитель директора ",
        label: "по логистике ООО “ГрандГранит” ",
      },
      {
        post: "Заместитель руководителя ",
        label:
          "Молодежного центра преподобного Серафима Саровского при храме Оптинских Старцев Белорусской Православной Церкви ",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.bel@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+37544 5300001"],
      },
      {
        img: "/img/mess.svg",
        label: "Telegram",
        type: "telegram",
        content: ["+37544 5300001"],
      },
      {
        img: "/img/mess.svg",
        label: "WhatsApp",
        type: "whatsapp",
        content: ["+37544 5300001"],
      },
    ],
    address:
      "Адрес представительства: Республика Беларусь, г. Минск, ул. Леонида Беды 11, корп. 1, офис 202",
  },
  {
    img: "/img/tatarstan.png",
    full_name: "Леушина Анастасия Владимировна",
    region: "ТАТАРСТАН",
    avatar: "/img/anastasia.jpeg",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Татарстану ",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.kaz@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 927 408 39 06"],
      },
    ],
    address:
      "Адрес представительства: Республика Татарстан, г. Казань, ул. Бухарская 4В, офис 4",
  },
  {
    img: "/img/mcs.png",
    full_name: "Галеев Дамир Инсафович",
    region: "МОСКВА",
    avatar: "/img/damir.png",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Москве ",
      },
      {
        post: "Генеральный директор ",
        label: "ГК “Policom” ",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.mos@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 987 290 16 90"],
      },
      {
        img: "/img/mess.svg",
        label: "WhatsApp",
        type: "whatsapp",
        content: ["+7 987 290 16 90"],
      },
    ],
    address:
      "Адрес представительства: 121609, город Москва, Осенняя улица, дом 23, этаж 1, пом. 1, ком. 5",
  },
  {
    img: "/img/ekb.png",
    full_name: "Новак Владимир Леонидович",
    region: "Екатеринбург",
    avatar: "/img/vladimir.jpeg",
    description: [
      {
        post: "Полномочный представитель ",
        label: "ТД “РегионСнаб” по Екатеринбургу ",
      },
      {
        post: "Вице-президент Председатель комитета ",
        label: "по инвестициям и внешне-экономическим связям",
      },
    ],
    social: [
      {
        img: "/img/mail.svg",
        label: "Электронная почта",
        type: "mail",
        content: ["tdregionsnab.ekb@list.ru"],
      },
      {
        img: "/img/phon.svg",
        label: "Телефон",
        type: "phone",
        content: ["+7 966 144 40 23"],
      },
    ],
    address:
      "Адрес представительства: 620012, город Екатеринбург, Стачек улица, дом 4",
  },
  {
    img: "/img/ch.png",
    full_name: "Представительство",
    region: "КИТАЙ",
  },
  {
    img: "/img/mong.png",
    full_name: "Представительство",
    region: "МОНГОЛИЯ",
  },
  {
    img: "/img/pr.png",
    full_name: "Представительство",
    region: "ПРАГА",
  },
  {
    img: "/img/Pol.png",
    full_name: "Представительство",
    region: "ПОЛЬША",
  },
  {
    img: "/img/penz.png",
    full_name: "Представительство",
    region: "ПЕНЗА",
  },
];

export { flagsList, personList };
