import { Box, Container } from "@mui/material";
import { styled } from "@mui/system";

import { Title, Line } from "../../../../components";
import SliderFlags from "./components/SliderFlags";

const ContainerOurRepresentations = styled(Container)({
  marginTop: 100,
});

const OurRepresentations = () => {
  return (
    <div id="about">
      <ContainerOurRepresentations maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Line />
          <Title>НАШИ ПРЕДСТАВИТЕЛЬСТВА</Title>
        </Box>
        <SliderFlags />
      </ContainerOurRepresentations>
    </div>
  );
};

export default OurRepresentations;
